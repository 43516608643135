body {
  //background: rgb(8,9,10);
  background: #000;
  color: #ccc;
  font-family: 'Inter', Arial, sans-serif; 
  font-size: 1.1rem;
}

img {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
}

span.line {
  display: inline-block;
}

a {
  color: #ccc;
}

a:hover {
  color: rgb(140, 149, 222);
}

.section {
  margin-top: 8rem;
}

.logo {
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.sectionHeader {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: rgb(140, 149, 222);
  margin-bottom: 4rem;
}

#skillset {
  padding-bottom: 3rem;
}

.skillsetText {
  font-size: 1.5rem;
}

#hero {
  color: #fff;
  min-height: 60vh;
  background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 70%), url('./img/body.webp');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}

#footer {
  border-top: 1px solid #757575;
  text-align: center;

  .logo {
    max-width: 10vw;
  }

}

.heroTitle {
  font-weight: 700;
  font-size: 4rem;
  margin-bottom: 5rem;
}

.transparent {
  color: #757575;
}

.heroSubtitle {
  font-size: 1.5rem;
  color: #ccc;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.white {
  color: #fff;
}

.contactText {
  font-size: 1.5rem;
  color: #ccc;
}

.socialContainer {
  text-align: center;
}

.socials {
  color: #ccc;
}

.socials:hover {
  color: rgb(140, 149, 222);
}

/* Mobile devices */

@media only screen and (max-width: 768px) {

  body {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .section {
    margin-top: 6rem;
  }

  .logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #hero {
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%), url('./img/body.webp');
    background-position: center;

    .transparent {
      color: #999;
    }

  }

  #skillset {
    padding-bottom: 1rem;
    span.line {
      display: inline;
    }
  }
  
  #footer {
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    .logo {
      max-width: 40vw;
    }

  }

  .heroTitle {
    text-shadow: 0 0 1rem #000;
    margin-top: 16rem;
    margin-bottom: 3rem;
    font-size: 3rem;
  }

  .heroSubtitle {
    font-size: 1.1rem;
    text-shadow: 0 0 2rem #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .skillsetText {
    font-size: 1.1rem;
  }

  .skillsetIcons{
    text-align: center;
  }

  .contactText {
    font-size: 1.1rem;
  }

}
